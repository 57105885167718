<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Please fill the clinic details</h4>
      <h4 class="mb-2 ml-5 mt-6" style="font-weight: 600">Account Details</h4>
      <form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.firstName') && clinicData.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.firstName') ? true : false"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.firstName')"
                v-model="clinicData.firstName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.lastName') && clinicData.lastName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.lastName') ? true : false"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.lastName')"
                v-model="clinicData.lastName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('basic.contactNumber') ? true : false"
                :success="
                  !errors.first('basic.contactNumber') &&
                  clinicData.contactNumber != ''
                "
                v-validate="'required'"
                val-icon-success="done"
                val-icon-danger="error"
                name="contactNumber"
                label="Contact Number"
                v-model="clinicData.contactNumber"
                class="w-full"
                data-vv-scope="basic"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("basic.contactNumber")
              }}</span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.email') && clinicData.email != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.email')"
                v-model="clinicData.email"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.password') && clinicData.password != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.password') ? true : false"
                v-validate="'required'"
                name="password"
                data-vv-as="password"
                label="Password"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.password')"
                autocomplete="new-password"
                v-model="clinicData.password"
                icon-no-border
                icon-pack="feather"
                :icon="passwordIcon"
                :type="passwordType"
                @click.native="showHidePassword($event)"
              />
            </div>
          </vs-col>
        </vs-row>

        <h4 class="mb-2 ml-5 mt-6" style="font-weight: 600">
          Business/Clinic Details
        </h4>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.companyName') &&
                  clinicData.companyName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.companyName') ? true : false"
                v-validate="'required'"
                name="companyName"
                data-vv-as="company name"
                label="Company Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.companyName')"
                v-model="clinicData.companyName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.clinicName') &&
                  clinicData.clinicName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.clinicName') ? true : false"
                v-validate="'required'"
                name="clinicName"
                :danger-text="errors.first('basic.clinicName')"
                data-vv-as="clinic name"
                label="Clinic Name"
                class="w-full"
                data-vv-scope="basic"
                v-model="clinicData.clinicName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.abn') && clinicData.abn != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.abn') ? true : false"
                v-validate="'required'"
                name="abn"
                :danger-text="errors.first('basic.abn')"
                data-vv-as="ABN"
                label="ABN"
                class="w-full"
                data-vv-scope="basic"
                v-model="clinicData.abn"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <div
                class="
                  vs-component vs-con-input-label vs-input
                  w-full
                  vs-input-primary
                "
                :class="{
                  'input-icon-validate-danger':
                    errors.first('displayAddress') ||
                    (clinicData.address.displayAddress &&
                      !clinicData.address.geometry.coordinates[0]) ||
                    checkDisplayAddress != clinicData.address.displayAddress,
                  'input-icon-validate-success':
                    clinicData.address.geometry.coordinates[0] &&
                    checkDisplayAddress == clinicData.address.displayAddress,
                }"
              >
                <!---->
                <label for class="vs-input--label">Clinic Street Address</label>
                <div class="vs-con-input">
                  <input
                    type="text"
                    placeholder
                    ref="autocomplete"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    data-vv-as="clinic street address"
                    name="displayAddress"
                    v-model="clinicData.address.displayAddress"
                    class="vs-inputx vs-input--input normal"
                    :class="{ hasValue: clinicData.address.displayAddress }"
                    :danger-text="'asd'"
                    style="border: 1px solid rgba(0, 0, 0, 0.2)"
                  />
                  <span class="input-icon-validate vs-input--icon-validate">
                    <i
                      v-if="
                        clinicData.address.geometry.coordinates[0] &&
                        checkDisplayAddress == clinicData.address.displayAddress
                      "
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                    <i
                      v-else-if="
                        errors.first('displayAddress') ||
                        (clinicData.address.displayAddress &&
                          !clinicData.address.geometry.coordinates[0]) ||
                        checkDisplayAddress != clinicData.address.displayAddress
                      "
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </div>
              </div>
              <span
                class="text-danger text-sm"
                style="
                  font-size: 0.65rem !important;
                  padding: 2px 4px;
                  padding-bottom: 4px;
                  display: block;
                "
                v-if="
                  errors.first('displayAddress') ||
                  (clinicData.address.displayAddress &&
                    !clinicData.address.geometry.coordinates[0]) ||
                  checkDisplayAddress != clinicData.address.displayAddress
                "
                >Enter valid address.</span
              >
            </div>
          </vs-col>
        </vs-row>

        <h4 class="mb-2 ml-5 mt-6 hidden" style="font-weight: 600">
          Nurse Details
        </h4>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5 hidden">
              <label for="">Is this a nurse account as well?</label>
              <v-select
                v-model="clinicData.isNurse"
                name="isNurse"
                :options="isNurseOptions"
                :clearable="false"
                :reduce="(label) => label.value"
                label="label"
                class="mt-1"
              />
              <span class="text-danger text-sm">{{
                errors.first("isNurse")
              }}</span>
            </div>
          </vs-col>

          <vs-col
            v-if="clinicData.isNurse"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.ahpraRegistrationNumber') &&
                  clinicData.ahpraRegistrationNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('basic.ahpraRegistrationNumber') ? true : false
                "
                v-validate="'required'"
                name="ahpraRegistrationNumber"
                :danger-text="errors.first('basic.ahpraRegistrationNumber')"
                data-vv-as="ahpra registration number"
                label="Ahpra Registration Number"
                class="w-full"
                data-vv-scope="basic"
                v-model="clinicData.ahpraRegistrationNumber"
              />
            </div>
          </vs-col>

          <vs-col
            v-if="clinicData.isNurse"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label class="vs-input--label">AHPRA Expiry date</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="clinicData.ahpraExiryDate"
                :input-class="{
                  'is-true':
                    !errors.has('basic.ahpraExiryDate') &&
                    clinicData.ahpraExiryDate,
                  'is-danger': errors.has('basic.ahpraExiryDate'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="clinicData.ahpraExiryDate"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('basic.ahpraExiryDate')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.65rem">{{
                errors.first("basic.ahpraExiryDate")
              }}</span>
              <input
                type="hidden"
                data-vv-as="APHRA expiry date"
                data-vv-validate-on="change"
                name="ahpraExiryDate"
                data-vv-scope="basic"
                v-validate="'required'"
                v-model="clinicData.ahpraExiryDate"
              />
            </div>
          </vs-col>

          <vs-col
            v-if="clinicData.isNurse"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.indemnityInsuranceProvider') &&
                  clinicData.indemnityInsuranceProvider != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('basic.indemnityInsuranceProvider')
                    ? true
                    : false
                "
                v-validate="'required'"
                name="indemnityInsuranceProvider"
                :danger-text="errors.first('basic.indemnityInsuranceProvider')"
                data-vv-as="indemnity insurance provider"
                label="Indemnity Insurance Provider"
                class="w-full"
                data-vv-scope="basic"
                v-model="clinicData.indemnityInsuranceProvider"
              />
            </div>
          </vs-col>

          <vs-col
            v-if="clinicData.isNurse"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.indemnityInsuranceNumber') &&
                  clinicData.indemnityInsuranceNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('basic.indemnityInsuranceNumber') ? true : false
                "
                v-validate="'required'"
                name="indemnityInsuranceNumber"
                :danger-text="errors.first('basic.indemnityInsuranceNumber')"
                data-vv-as="indemnity insurance number"
                label="Indemnity Insurance Number"
                class="w-full"
                data-vv-scope="basic"
                v-model="clinicData.indemnityInsuranceNumber"
              />
            </div>
          </vs-col>

          <vs-col
            v-if="clinicData.isNurse"
            vs-type="flex"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label class="vs-input--label">Indemnity Insurance Expiry</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="clinicData.indemnityInsuranceExpiry"
                :input-class="{
                  'is-true':
                    !errors.first('basic.indemnityInsuranceExpiry') &&
                    clinicData.indemnityInsuranceExpiry,
                  'is-danger': errors.first('basic.indemnityInsuranceExpiry'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="clinicData.indemnityInsuranceExpiry"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('basic.indemnityInsuranceExpiry')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.65rem">{{
                errors.first("basic.indemnityInsuranceExpiry")
              }}</span>
              <input
                type="hidden"
                data-vv-as="Indemnity Insurance Expiry"
                data-vv-validate-on="change"
                data-vv-scope="basic"
                name="indemnityInsuranceExpiry"
                v-validate="'required'"
                v-model="clinicData.indemnityInsuranceExpiry"
              />
            </div>
          </vs-col>
        </vs-row>

        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <vs-button
              style="margin-left: 16px"
              size="normal"
              @click="saveData"
              icon-pack="feather"
              class="mr-2"
              >Create Clinic</vs-button
            >
          </vs-col>
        </vs-row>
      </form>
    </vs-card>
  </div>
</template>

<script>
import clinicViewEditDetail from "../../components/clinics/viewEditDetail.vue";
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import axios from "@/axios";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import ClinicOpeningHours from "./ClinicOpeningHours.vue";
import ClinicPhotos from "./ClinicPhotos";

const dict = {
  custom: {
    ahpraExiryDate: {
      required: "Please enter valid AHPRA Expiry Date",
    },
  },
};
Validator.localize("en", dict);

export default {
  name: "clinicViewEditDetail",
  inject: ["$validator"],
  components: {
    ClinicOpeningHours,
    ClinicPhotos,
    "v-select": vSelect,
    Datepicker,
  },
  props: {
    backRoute: {
      type: String,
      default: "SuperAdminClinic",
    },
    doctorClinicId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    disabledDates: { to: new Date() },
    isNurseOptions: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    checkDisplayAddress: "",
    addressData: [],
    clinicData: {
      userType: "clinic",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      companyName: "",
      clinicName: "",
      abn: "",
      address: {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        geometry: { type: "Point", coordinates: [] },
      },
      status: "Active",
      contactNumber: "",
      isNurse: false,
      ahpraRegistrationNumber: "",
      ahpraExiryDate: "",
      indemnityInsuranceProvider: "",
      indemnityInsuranceNumber: "",
      indemnityInsuranceExpiry: "",
    },
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
  }),
  methods: {
    ...mapActions("clinic", ["createClinicBasic", "removeClinicImages"]),
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "abnCertificateUpload") {
            document.getElementById("abnCertificateId").style.borderColor =
              "#cccccc";
            self.clinicData.abnCertificate = res.data.Location;
            this.errors.remove("abnCertificate");
          }

          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    removeImage(imageUrl, selector, loadingClass) {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `#${selector}`,
        scale: 0.45,
        text: "removing",
      });

      let self = this;

      self
        .removeClinicImages(imageUrl)
        .then((response) => {
          self.clinicData[loadingClass] = self.clinicData[loadingClass].filter(
            (value) => value.photoUrl != imageUrl
          );
          self.$vs.loading.close(`#${selector} > .con-vs-loading`);
        })
        .catch((error) => {
          self.$vs.notify({
            title: "Removing Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
          self.$vs.loading.close(`#${selector} > .con-vs-loading`);
        });
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async saveData() {
      let flag = true;
      let self = this;
      let user = self.$store.state.AppActiveUser.userRole;
      let isValid = await this.$validator.validateAll("basic");

      if (
        !self.clinicData.address.geometry.coordinates[0] ||
        self.checkDisplayAddress != self.clinicData.address.displayAddress
      ) {
        flag = false;
        this.errors.add({
          field: "displayAddress",
          msg: "Enter valid address.",
        });
      } else {
        this.errors.remove("displayAddress");
      }

      if (isValid && flag) {
        let formData = this.clinicData;
        formData.emailVerifiedAt = new Date().toISOString();
        formData.hasApprovedByAdmin = true;

        if (this.doctorClinicId && this.doctorClinicId !== "") {
          formData.doctorClinicId = this.doctorClinicId;
        }

        this.$vs.loading();

        await this.createClinicBasic(formData)
          .then((res) => {
            self.$vs.notify({
              title: "Clinic Created",
              text: "Clinic created Successfully.",
              color: "success",
            });
            self.$router.push({ name: "SuperAdminDoctorClinics" });
            self.$vs.loading.close();
          })
          .catch((err) => {
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
              self.$vs.notify({
                title: "Failed",
                text: "Failed to created clinic. Try again!",
                color: "danger",
              });
            }
            if (err.status === 417) {
              if (err.data.title === "Invalid email") {
                self.errors.add({
                  field: "email",
                  msg: err.data.message,
                  scope: "basic",
                });
              } else if (err.data.title === "Invalid ABN") {
                self.errors.add({
                  field: "abn",
                  msg: err.data.message,
                  scope: "basic",
                });
              }
              self.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
            }
            self.$vs.loading.close();
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
  },

  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;

    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: "au" } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.addressData = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.clinicData.address.displayAddress = place.formatted_address;
      this.clinicData.address.geometry.coordinates[0] = lon;
      this.clinicData.address.geometry.coordinates[1] = lat;

      let self = this;

      self.clinicData.address.suburb = "";
      self.clinicData.address.state = "";
      self.clinicData.address.postCode = "";
      if (
        place &&
        place.address_components &&
        place.address_components.length > 0
      ) {
        place.address_components.forEach(function (gAdd) {
          if (gAdd.types[0] === "administrative_area_level_2") {
            self.clinicData.address.suburb = gAdd.long_name;
            self.errors.remove("clinicSuburb");
          }
          if (gAdd.types[0] === "administrative_area_level_1") {
            self.clinicData.address.state = gAdd.long_name;
            self.errors.remove("clinicState");
          }
          if (gAdd.types[0] === "postal_code") {
            self.clinicData.address.postCode = gAdd.long_name;
            self.errors.remove("clinicPostCode");
          }
        });
      }
    });
  },
  watch: {},
};
</script>

<style scoped>
.error-text {
  font-size: 0.65rem !important;
  padding: 2px 4px 4px;
  display: block;
  margin-top: -6px;
}

.text-sm {
  font-size: 0.65rem !important;
}

.no-margin-span {
  margin-top: 0 !important;
}
</style>
